
import { Options, Vue } from "vue-class-component";
import Navbar from "@/core/components/Navbar.vue";
import { ProvideReactive, Watch } from "vue-property-decorator";
import { IAuthInfo } from "./core/models/auth-info";
import { authService } from "./core/services/auth-service";
import { RouteLocation } from "vue-router";
import LoadingCircle from "./common/components/loading-circle/LoadingCircle.vue";

@Options({
	components: {
		Navbar,
LoadingCircle
	}
})
export default class App extends Vue {
	@ProvideReactive("authInfo") public authInfo: IAuthInfo = {} as IAuthInfo;
	public initialized = false;

	async created(): Promise<void> {
		this.authInfo = await authService.getAuthInfo();
		this.initialized = true;
	}

	@Watch("$route.meta.title", { immediate: true })
	public onPageTitleChange(
		title: string | ((route: RouteLocation) => string)
	): void {
		const titleStr = typeof title === "function" ? title(this.$route) : title; //support for dynamic titles
		document.title = title
			? [this.$t(titleStr), " | ", this.$t("siteName")].join("")
			: this.$t("siteName");
	}
}
