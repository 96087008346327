
import { toLower } from "lodash";
import { Options, Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";
import { IAuthInfo } from "@/core/models/auth-info";
import { RouteLocation } from "vue-router";
import NavbarSearch from "./NavbarSearch.vue";

@Options({
	components: {
		NavbarSearch
	}
})
export default class HelloWorld extends Vue {
	@InjectReactive() public authInfo!: IAuthInfo; 

	hideLanguageSelector: boolean = false;

	get returnUrl(): string {
		return encodeURIComponent(location.pathname+location.search);
	}

	get isInternal(): boolean {
		const userType = this.$router.currentRoute.value.query["UserType"] as string;
		return toLower(userType) === "internal";
	}

	get pageTitle(): string {
		const title = this.$route.meta.title as string | ((route: RouteLocation) => string);
		const titleStr = typeof title === "function" ? title(this.$route) : title; //support for dynamic titles
		return titleStr ? this.$t(titleStr) : "";
	}
}
