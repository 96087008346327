export default {
  "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REST Documentation"])},
  "navbar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST documentation"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "search": {
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "sectionSelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search section"])},
      "allSectionsOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sections"])},
      "searchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search results"])}
    }
  },
  "guide": {
    "pageTitle": {
      "QuickStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Start Tutorial"])},
      "RestManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest Manual"])},
      "UseCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Case"])}
    }
  },
  "apiDoc": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API documentation"])},
    "pagetree": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST services"])}
    },
    "openApi": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "operation": {
        "copyLinkToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link to clipboard"])},
        "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examples"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])}
      }
    }
  },
  "delta": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version changes"])},
    "pagetree": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST services"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose versions for comparison"])}
    },
    "versionControls": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon version changes"])},
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version and release"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "options": {
        "ignoreNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore namespace changes"])}
      },
      "tagFilter": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service filters"])},
        "tags": {
          "service-unmodified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmodified"])},
          "service-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified"])},
          "service-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
          "service-removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])}
        }
      }
    },
    "serviceFileDelta": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a service schema"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
    }
  },
  "pagetree": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST services"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
  }
}