import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import "trix/dist/trix.css";
import "trix/dist/trix.js";
import Trix from "trix";

(async function () { 
	//Init custom trix editor custom buttons and other options
	Trix.config.blockAttributes.aside = { tagName: "aside", nestable: false };
	Trix.config.blockAttributes.heading5 = { tagName: "h5", terminal: true, breakOnReturn: true, group: false };
	Trix.config.blockAttributes.indent = { tagName: "indent", terminal: true, breakOnReturn: true, group: false };
	Trix.config.toolbar.getDefaultHTML = function () {
		return "";
	};
	//
	const app = createApp(App)
		.use(i18n)
		.use(store)
		.use(router);

	await router.isReady();
	app.mount("#app");
})();
