
import { Vue } from "vue-class-component";
import IBadgeModel from "./models/badge-model";
import { homeService } from "./services/home-service";

export default class HomeView extends Vue {
	public badges: IBadgeModel[] = [];

	created(): void {
		homeService.getBadges()
			.then(badges => {
				this.badges = badges;
			});
	}
}
