import { trimStart } from "lodash";
import router from "@/router/index";
export default class FetchService {
	private get baseUrl(): string {
		return `/_/${router.currentRoute.value.params.lang || "lv"}/`;
	}

	public get<TResponse>(url: string, queryParams?: URLSearchParams): Promise<TResponse> {
		const fullUrl = this.getFullUrl(url, queryParams);
		const requestOptions = <RequestInit>{
			method: "GET"
		};
		return this.handleReqeust(fullUrl, requestOptions);
	}

	public post<TRequestBody, TResponse>(url: string, data: TRequestBody, queryParams?: URLSearchParams): Promise<TResponse> {
		const fullUrl = this.getFullUrl(url, queryParams);
		const requestOptions = <RequestInit>{
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		};
		return this.handleReqeust(fullUrl, requestOptions);
	}

	private getFullUrl(relativeUrl: string, queryParams?: URLSearchParams): string {
		const fullUrl = this.baseUrl + trimStart(relativeUrl, "/");
		if (!queryParams) {
			return fullUrl;
		}
		
		return [fullUrl, "?", queryParams.toString()].join("");
	}

	private handleReqeust<TResponse>(fullUrl: string, requestOptions: RequestInit): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			fetch(fullUrl, requestOptions)
				.then(response => {
					if (response.ok) {
						resolve(response.json());
						return;
					}

					response.text().then(error => reject(error));
				})
				.catch(error => {
					reject(error);
				});
		});
	}
}

export const fetchService = new FetchService();