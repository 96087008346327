import { fetchService } from "@/core/services/fetch-service";
import { ISearchSection, ISearchRequest, ISearchResponse } from "../models/search";

export default class SearchService {
	public getSearchSections(): Promise<ISearchSection[]> {
		return fetchService.get<ISearchSection[]>("/GlobalSearch/SearchSections");
	}

	public search(request: ISearchRequest): Promise<ISearchResponse> {
		return fetchService.post<ISearchRequest, ISearchResponse>("/GlobalSearch", request);
	}
}

export const searchService = new SearchService();