import { createRouter, createWebHistory, RouteLocation, RouteRecordRaw } from "vue-router";
import i18n from "../i18n";
import Home from "@/modules/home/HomeView.vue";
import EmptyRouterView from "@/core/views/EmptyRouterView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/:lang(lv|en)?",
		component: EmptyRouterView,
		children: [
			{
				path: "",
				name: "Home",
				component: Home
			},
			{
				path: "Guide/:section",
				name: "Guide",
				component: () => import(/* webpackChunkName: "guide" */ "../modules/guide/GuideView.vue"),
				meta: { title: (route: RouteLocation) => `guide.pageTitle.${route.params.section}` }
			},
			{ 
				path: "API", 
				redirect: (to) => ({ 
					path: to.path.replace(/API/i, "ApiDoc"),
					replace: true
				})
			},
			{
				path: "ApiDoc",
				name: "ApiDoc",
				component: () => import(/* webpackChunkName: "api-doc" */ "../modules/api-doc/ApiDocView.vue"),
				meta: { title: "apiDoc.pageTitle" }
			},
			{
				path: "Delta",
				name: "Delta",
				component: () => import(/* webpackChunkName: "delta" */ "../modules/delta/DeltaView.vue"),
				meta: { title: "delta.pageTitle" }
			},
			{ 
				path: ":pathAny(.*)*", 
				redirect: { name: "Home" }
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	if (!to.matched.length) {
		next();
		return;
	}
	// use the language from the routing param or default language
	let lang = <string>to.params.lang;
	if (!to.params.lang) {
		lang = from.params.lang ? <string>from.params.lang : "lv";
		next({
			path: `/${lang}${to.fullPath}`,
			query: to.query,
			hash: to.hash,
			replace: true
		});
	} else {
		i18n.global.locale.value = lang;
		next();
	}
});

export default router;
