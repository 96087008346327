export default {
  "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest dokumentācija"])},
  "navbar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST dokumentācija"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēgties"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atslēgties"])},
    "search": {
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt"])},
      "sectionSelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt sadaļā"])},
      "allSectionsOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas sadaļas"])},
      "searchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meklēšanas rezultāti"])}
    }
  },
  "guide": {
    "pageTitle": {
      "QuickStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ātrais sākums"])},
      "RestManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API lietošanas pamatprincipi"])},
      "UseCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biznesa scenāriji"])}
    }
  },
  "apiDoc": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API dokumentācija"])},
    "pagetree": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST servisi"])}
    },
    "openApi": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versija"])},
      "operation": {
        "copyLinkToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopēt saiti starpliktuvē"])},
        "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piemēri"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēt"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopēt"])}
      }
    }
  },
  "delta": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiju izmaiņas"])},
    "pagetree": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST servisi"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu norādiet versijas salīdzināšanai"])}
    },
    "versionControls": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon versiju izmaiņas"])},
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versija un laidiens"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz"])},
      "options": {
        "ignoreNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorēt namespace izmaiņas"])}
      },
      "tagFilter": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servisu filtri"])},
        "tags": {
          "service-unmodified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez izmaiņām"])},
          "service-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificētie"])},
          "service-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunie"])},
          "service-removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēstie"])}
        }
      }
    },
    "serviceFileDelta": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izmaiņas"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties servisa shēmu"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākamās"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējās"])}
    }
  },
  "pagetree": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon REST servisi"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt"])}
  }
}