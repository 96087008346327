
import { Vue, Options } from "vue-class-component";
import { searchService } from "@/core/services/search-service";
import {
	ISearchRequest,
	ISearchResult,
	ISearchSection
} from "../models/search";
import { debounce, orderBy } from "lodash";
import LoadingCircle from "@/common/components/loading-circle/LoadingCircle.vue";
import { Watch } from "vue-property-decorator";

@Options({
	components: {
		LoadingCircle
	}
})
export default class HelloWorld extends Vue {
	searchDelayMilliseconds = 500;
	batchSize = 15;

	isLoading = false;
	currentBatchNumber: number = -1;
	totalCount: number = -1;
	searchTimeoutId: number | null = null;
	isSidebarOpened: boolean = false;
	searchInput: string = "";
	currentSearchPhrase: string = "";
	selectedSection: ISearchSection | null = null;
	searchSections: ISearchSection[] | null = null;
	searchResults: ISearchResult[] = [];

	public onScroll(event: Event): void {
		const element = event.target as HTMLElement;
		if (
			element.scrollHeight - element.scrollTop <=
			element.clientHeight + 300
		) {
			if (
				(this.currentBatchNumber + 1) * this.batchSize < this.totalCount &&
				!this.isLoading
			) {
				this.loadNext();
			}
		}
	}

	async created(): Promise<void> {
		await this.setSearchSections();
	}

	@Watch("isSidebarOpened")
	onSidebarToggle(value: boolean): void {
		document.getElementsByTagName("body")[0].classList.toggle("modal-open", value);
	}

	//Functions
	public openSidebar(): void {
		this.isSidebarOpened = true;
		//If empty, retrive last search from session storage
		if (!this.searchInput) {
			const lastGlobalSearch =
				window.sessionStorage.getItem("lastGlobalSearch");
			if (lastGlobalSearch) {
				const lastSearch = JSON.parse(lastGlobalSearch);
				this.searchInput = lastSearch.searchText;
				this.selectedSection = this.searchSections?.find(section => section.code == lastSearch.searchSection) || null ;
				this.debouncedSearch();
			}
		}

		setTimeout(() => {
			(this.$refs.sidebarInput as HTMLElement).focus();
		}, 100);
	}

	closeSidebar(): void {
		this.isSidebarOpened = false;
	}

	async setSearchSections(): Promise<void> {
		let searchSections = await searchService.getSearchSections();
		searchSections.forEach((section) => section.name = this.$t(section.name));
		searchSections = orderBy(searchSections, (section) => section.name);
		this.searchSections = searchSections;
	}

	debouncedSearch = debounce(
		this.search.bind(this),
		this.searchDelayMilliseconds
	);

	async search(): Promise<void> {
		if (this.isLoading) {
			return;
		}
		this.searchResults = [];
		this.isLoading = true;
		const requestData = {
			searchSection: this.selectedSection?.code ?? null,
			searchText: this.searchInput,
			batchSize: this.batchSize,
			batchNumber: 0
		} as ISearchRequest;

		window.sessionStorage.setItem(
			"lastGlobalSearch",
			JSON.stringify({
				searchText: requestData.searchText,
				searchSection: requestData.searchSection
			})
		);

		this.currentSearchPhrase = requestData.searchText;

		if (!requestData.searchText || requestData.searchText.length < 3) {
			this.searchResults = [];
			this.totalCount = -1;
			this.isLoading = false;
			return;
		}

		const response = await searchService.search(requestData);
		this.searchResults = response.results;

		this.totalCount = response.totalCount;
		this.currentBatchNumber = 0;
		this.isLoading = false;
	}

	async loadNext(): Promise<void> {
		this.isLoading = true;

		this.currentBatchNumber += 1;
		const requestData = {
			searchSection: this.selectedSection?.code ?? null,
			searchText: this.searchInput,
			batchSize: this.batchSize,
			batchNumber: this.currentBatchNumber
		} as ISearchRequest;

		const response = await searchService.search(requestData);
		this.searchResults.push(...response.results);

		this.isLoading = false;
	}

	public transformUrl(srcUrl: string): string {
		const url = new URL(srcUrl, location.origin);
		url.searchParams.set("SearchPhrase", this.currentSearchPhrase);
		return url.toString().replace(location.origin, "");
	}
}
