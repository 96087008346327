<template>
	<div class="loading-circle">
		<div class="loading-circle__animation"></div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading-circle {
	overflow: hidden;
}

.loading-circle__animation {
	margin: 30px auto auto;
	height: 100px;
	width: 100px;
}

.loading-circle__animation::after {
	content: " ";
	display: block;
	border: 16px solid #f3f3f3;
	border-top: 16px solid #007d9b;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	animation: loading-circle-spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes loading-circle-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>