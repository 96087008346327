import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fd97252"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "component-badge" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges, (badge) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-6 col-sm-3",
        key: badge.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: 'data:image/png;base64,' + badge.imgBase64,
            alt: badge.title,
            class: "component-badge-img"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t(badge.title)), 1)
        ]),
        (badge.openInNewTab)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: badge.link,
              class: "stretched-link",
              target: "_blank"
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: badge.link,
              class: "stretched-link"
            }, null, 8, ["to"]))
      ]))
    }), 128))
  ]))
}